import React, { useState } from "react"
import styles from "./component.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import { Navigation, Scrollbar, A11y } from "swiper"
import imageSrc from "../../../lib/image-src"
import { StructuredText } from "react-datocms"

const ImageSlider = ({ data: { images, imageSliderText } }) => {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <div className={styles.container}>
      <div className={styles.swiperContainer}>
        <Swiper
          onSlideChange={()=> {setCurrentIndex(swiper.activeIndex)}}
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          className={styles.swiper}
          onSwiper={setSwiper}
        >
          {images.map((image, i) => {
            return (
              <SwiperSlide>
                {image.isImage ? (
                  <img src={imageSrc(image)} className={styles.image} key={i} />
                ) : (
                  <video
                    controls={true}
                    poster={image.thumbnailUrl}
                    data-setup="{}"
                    loop={true}
                    className={styles.videoBanner}
                  >
                    <source
                      src={image.video.mp4Url}
                      type="video/mp4"
                    />
                  </video>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div>
          {images.map((m, index) => {
            return (
              <span
                key={index}
                className={currentIndex == index ? styles.activeIndex : styles.indexSwiper}
                onClick={() => {
                  if (swiper) {
                    swiper.slideTo(index)
                    setCurrentIndex(index)
                  }
                }}
              >
                {index + 1}
              </span>
            )
          })}
        </div>
      </div>
      <div className={styles.text}>
        <StructuredText data={imageSliderText} />
      </div>
    </div>
  )
}
export default ImageSlider
