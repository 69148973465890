import React from "react"
import { Helmet } from "react-helmet"
import styles from "./new-header.module.css"
import datoLogo from "../../../images/dato-logo.svg"
import gatsbyLogo from "../../../images/gatsby-logo.svg"
import individualLogo from "../../../images/digitalwert-individual-dev-logo.svg"
import magentoLogo from "../../../images/magento-logo.svg"
import shopwareLogo from "../../../images/shopware-logo.svg"
import typoLogo from "../../../images/typo3-logo.svg"
import wordpressLogo from "../../../images/wordpress-logo.svg"

const Header = props => {
  return (
    <div id={props.Anker} className={styles.container}>
      <div className={styles.headerContainer}>
        <Helmet htmlAttributes={{ lang: "de" }} defer={false}>
          <link
            href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <div className={styles.projectDescription}>
          <p className={styles.projectDescriptionName}>{props.CostumerShort}</p>
          <h1 className={styles.projectDescriptionBanner}>{props.title}</h1>
        </div>
        <div className={styles.logos}>
          {props.Tech.split(", ").map(tec => {
            switch (tec) {
              case "DatoCMS":
                return <img className={styles.logo} src={datoLogo} />
              case "Gatsby":
                return <img className={styles.logo} src={gatsbyLogo} />
              case "Shopware":
                return <img className={styles.logo} src={shopwareLogo} />
              case "Magento":
                return <img className={styles.logo} src={magentoLogo} />
              case "Typo":
                return <img className={styles.logo} src={typoLogo} />
              case "Wordpress":
                return <img className={styles.logo} src={wordpressLogo} />
              case "Individual":
                return <img className={styles.logo} src={individualLogo} />
              default:
                return null
            }
          })}
        </div>
      </div>
      {props.children}
      <div className={styles.projectDescritionContainer}>
        <p className={styles.projectDescriptionContent}>{props.teaserText}</p>
        <div className={styles.projectDetails}>
          <div>
            <p className={styles.projectDetailsTitle}>Ressort</p>
            <p className={styles.projectDetailsAnswer}>{props.Ressort}</p>
          </div>
          <div>
            <p className={styles.projectDetailsTitle}>Technologie</p>
            <p className={styles.projectDetailsAnswer}>{props.Tech}</p>
          </div>
          <div>
            <p className={styles.projectDetailsTitle}>Kunde</p>
            <p className={styles.projectDetailsAnswer}>{props.CostumerLong}</p>
          </div>
          <div>
            <p className={styles.projectDetailsTitle}>Webseite</p>
            <p className={styles.projectDetailsAnswer}>{props.WebSite}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
