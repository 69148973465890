import React from "react"
import styles from "./component.module.css"
import { StructuredText } from "react-datocms"

const CtaBanner = ({ data: { headline, text, linkButton } }) => {
  return (
    <div className={styles.container}>
      <div className={styles.widthContainer}>
        <div className={styles.header}>
          <span>{headline}</span>
        </div>
        <div className={styles.text}>
          <span>{text}</span>
          <StructuredText
            renderLinkToRecord={({ record, children }) => {
              return (
                <a className={styles.button} href={`/${record.slug}`}>
                  {children}
                </a>
              )
            }}
            data={linkButton}
          />
        </div>
      </div>
    </div>
  )
}
export default CtaBanner
