import React, { useState } from "react"
import { graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import imageSrc from "./../lib/image-src"

import styles from "../styles/new-reference.module.css"
import TopHeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import Header from "../components/refrence-components/new-header/new-header"
import HeaderImage from "../components/refrence-components/header/headerImage/headerImage"
import Tasks from "../components/refrence-components/tasks/tasks"
import BrandRelaunch from "../components/refrence-components/brand-relaunch/brand-relaunch"
import TextImage from "../components/refrence-components/text-image/text-image"
import Qoute from "../components/refrence-components/quote/quote"
import ProductDetailsPage from "../components/refrence-components/product-details-page/product-details-page"
import Marketing from "../components/refrence-components/marketing/marketing"
import Images from "../components/refrence-components/images/images"
import Separator from "../components/refrence-components/separator/separator"
import OurProjects from "../components/our-projects/our-projects"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent"
import ImageSlider from "../components/refrence-components/image-slider"
import { StructuredText } from "react-datocms"
import CtaBanner from "../components/refrence-components/cta-banner"
import ImageText from "../components/refrence-components/image-text"
import PicDivider from "../components/themenseite-components/picture-divider/picturre-divider"

export default ({ data, pageContext, location }) => {
  const cookieConsentRef = React.createRef()

  let sidebarItems = ["Einstieg"]
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <div>
        <Helmet htmlAttributes={{ lang: "de" }}>
          <title>{data.datoCmsReferenz.seo.title}</title>
          <meta
            name="description"
            content={data.datoCmsReferenz.seo.description}
          />
        </Helmet>
        <header className={styles.header}>
          <TopHeaderMenu url={location.href} page="refrenz" />
        </header>
        <main className={styles.refrenzMain}>
          <div>
            <VisibilitySensor partialVisibility={true}>
              <Header
                Anker="Einstieg"
                Ressort={data.datoCmsReferenz.ressort}
                Tech={data.datoCmsReferenz.technologie}
                CostumerLong={data.datoCmsReferenz.kundeLang}
                WebSite={data.datoCmsReferenz.website}
                CostumerShort={data.datoCmsReferenz.kundeKurz}
                title={data.datoCmsReferenz.titelDerReferenz}
                teaserText={data.datoCmsReferenz.teaserText}
              >
                <div
                  className={styles.imageBanner}
                  style={{
                    backgroundImage: data.datoCmsReferenz.backgroundImage
                      ? "url(" + data.datoCmsReferenz.backgroundImage.url + ")"
                      : "linear-gradient( " +
                        data.datoCmsReferenz.backgroundColorFadeOne.hex +
                        ", " +
                        data.datoCmsReferenz.backgroundColorFadeTwo.hex +
                        ")",
                  }}
                >
                  {data.datoCmsReferenz.image.isImage ? (
                    <img
                      src={imageSrc(data.datoCmsReferenz.image)}
                      style={{ paddingTop: "50px" }}
                    />
                  ) : (
                    <video
                      controls={true}
                      poster={imageSrc(data.datoCmsReferenz.videoCover)}
                      data-setup="{}"
                      loop={true}
                      className={styles.videoBanner}
                      style={{
                        paddingTop: "50px"
                      }}
                    >
                      <source
                        src={data.datoCmsReferenz.image.video.mp4Url}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              </Header>
            </VisibilitySensor>
            {data.datoCmsReferenz.content.map((contentElement, i) => {
              if (!contentElement.id) {
                return <div key={i}>unknown content element id</div>
              }

              switch (contentElement.internal.type) {
                case "DatoCmsMoodPicture":
                  if (
                    data.datoCmsReferenz.content[i].backgroundColor === "" &&
                    data.datoCmsReferenz.content[i].heading === ""
                  )
                    return (
                      <HeaderImage
                        key={i}
                        Img={imageSrc(
                          data.datoCmsReferenz.content[i].imageFullWidth
                        )}
                        bgImgSrc={imageSrc(
                          data.datoCmsReferenz.content[0].backgroundImage
                        )}
                        alt={
                          data.datoCmsReferenz.content[0]?.imageFullWidth?.alt
                        }
                        Shadow={contentElement.shadow}
                      />
                    )
                  if (data.datoCmsReferenz.content[i].backgroundColor !== "")
                    return (
                      <ProductDetailsPage
                        key={i}
                        BgColor={
                          data.datoCmsReferenz.content[i].backgroundColor
                        }
                        Img={imageSrc(
                          data.datoCmsReferenz.content[i].imageFullWidth
                        )}
                        Headline={data.datoCmsReferenz.content[i].heading}
                        ImgDesc={
                          data.datoCmsReferenz.content[i].imageDescription
                        }
                        Shadow={contentElement.shadow}
                      />
                    )
                  return ""
                case "DatoCmsTestimonial":
                  return (
                    <Qoute
                      key={i}
                      Avatar={imageSrc(data.datoCmsReferenz.content[i].image1)}
                      Qoute={data.datoCmsReferenz.content[i].quote1}
                      AuthorName={data.datoCmsReferenz.content[i].name1}
                    />
                  )
                case "DatoCmsAufgabenstellung":
                  if (data.datoCmsReferenz.content[i].anker) {
                    sidebarItems.push(data.datoCmsReferenz.content[i].anker)
                  }
                  return (
                    <VisibilitySensor key={i} partialVisibility={true}>
                      <Tasks
                        key={i}
                        Anker={data.datoCmsReferenz.content[i].anker}
                        Headline={data.datoCmsReferenz.content[i].berschriftH2}
                        Text={data.datoCmsReferenz.content[i].text}
                        List={data.datoCmsReferenz.content[i].liste}
                        Img1={imageSrc(data.datoCmsReferenz.content[i].bild1)}
                        Img1Desc={
                          data.datoCmsReferenz.content[i].beschreibungBild1
                        }
                        Img2Desc={
                          data.datoCmsReferenz.content[i].beschreibungBild2
                        }
                        Img2={imageSrc(data.datoCmsReferenz.content[i].bild2)}
                        Shadow={contentElement.shadow}
                      />
                    </VisibilitySensor>
                  )
                case "DatoCmsTextWithOptionForAlignPosition":
                  if (data.datoCmsReferenz.content[i].anker) {
                    sidebarItems.push(data.datoCmsReferenz.content[i].anker)
                  }
                  return (
                    <Marketing
                      key={i}
                      Anker={data.datoCmsReferenz.content[i].anker}
                      Headline={data.datoCmsReferenz.content[i].berschriftH2}
                      LText={data.datoCmsReferenz.content[i].textLeftCenter}
                      RText={data.datoCmsReferenz.content[i].textRightWithHtml}
                      Color={data.datoCmsReferenz.content[i].hintergrundfarbe}
                    />
                  )
                case "DatoCmsMarke":
                  if (data.datoCmsReferenz.content[i].anker) {
                    sidebarItems.push(data.datoCmsReferenz.content[i].anker)
                  }
                  return (
                    <VisibilitySensor key={i} partialVisibility={true}>
                      <BrandRelaunch
                        key={i}
                        Anker={data.datoCmsReferenz.content[i].anker}
                        Headline={data.datoCmsReferenz.content[i].berschriftH2}
                        Partner={
                          data.datoCmsReferenz.content[i].berschriftPartnerH2
                        }
                        Logo={
                          data.datoCmsReferenz.content[i].ueberschriftLogoH3
                        }
                        LogoImg1={imageSrc(
                          data.datoCmsReferenz.content[i].imageLogo
                        )}
                        LogoImg1Alt={
                          data.datoCmsReferenz.content[i].imageLogo !== null
                            ? data.datoCmsReferenz.content[i].imageLogo?.alt
                            : null
                        }
                        LogoImgDesc={
                          data.datoCmsReferenz.content[i].image1LogoDescription
                        }
                        LogoImg2={imageSrc(
                          data.datoCmsReferenz.content[i].imageLogo2
                        )}
                        LogoImg2Alt={
                          data.datoCmsReferenz.content[i].imageLogo2 !== null
                            ? data.datoCmsReferenz.content[i].imageLogo2?.alt
                            : null
                        }
                        LogoImg2Desc={
                          data.datoCmsReferenz.content[i].imageLogo2Description
                        }
                        Icon={data.datoCmsReferenz.content[i].berschriftIcon}
                        IconImg={imageSrc(
                          data.datoCmsReferenz.content[i].imageIconLogo
                        )}
                        IconImgAlt={
                          data.datoCmsReferenz.content[i].imageIconLogo !== null
                            ? data.datoCmsReferenz.content[i].imageIconLogo?.alt
                            : null
                        }
                        ColorHeadline={
                          data.datoCmsReferenz.content[i].berschriftH3Farbe
                        }
                        ColorImg={imageSrc(
                          data.datoCmsReferenz.content[i].farbeImage
                        )}
                        ColorDesc={
                          data.datoCmsReferenz.content[i].farbeImageDescription
                        }
                        Desc={data.datoCmsReferenz.content[i].description}
                        TypoHeadline={
                          data.datoCmsReferenz.content[i].typographyBerschriftH3
                        }
                        TypoImg1={imageSrc(
                          data.datoCmsReferenz.content[i].typographyLogo1
                        )}
                        TypoDesc1={
                          data.datoCmsReferenz.content[i]
                            .typographyLogo1Description
                        }
                        TypoImg2={imageSrc(
                          data.datoCmsReferenz.content[i].typographyLogo2
                        )}
                        TypoDesc2={
                          data.datoCmsReferenz.content[i]
                            .typographyLogo2Description
                        }
                        TypoImg3={imageSrc(
                          data.datoCmsReferenz.content[i].typographyLogo3
                        )}
                        TypoDesc3={
                          data.datoCmsReferenz.content[i]
                            .typographyLogo3Description
                        }
                        Bg={imageSrc(
                          data.datoCmsReferenz.content[i].hintergrundbild
                        )}
                      />
                    </VisibilitySensor>
                  )
                case "DatoCmsMultiImage":
                  return (
                    <Images
                      key={i}
                      BgColor={data.datoCmsReferenz.content[i].hintergrundfarbe}
                      Img1={
                        data.datoCmsReferenz.content[i].image[0] !== null
                          ? imageSrc(data.datoCmsReferenz.content[i].image[0])
                          : null
                      }
                      Img1Alt={
                        data.datoCmsReferenz.content[i].image[0] !== null
                          ? data.datoCmsReferenz.content[i].image[0]?.alt
                          : null
                      }
                      Img2={
                        data.datoCmsReferenz.content[i].image[1] !== null
                          ? imageSrc(data.datoCmsReferenz.content[i].image[1])
                          : null
                      }
                      Img2Alt={
                        data.datoCmsReferenz.content[i].image[1] !== null
                          ? data.datoCmsReferenz.content[i].image[1]?.alt
                          : null
                      }
                      Img3={
                        data.datoCmsReferenz.content[i].image[2] !== null
                          ? imageSrc(data.datoCmsReferenz.content[i].image[2])
                          : null
                      }
                      Img3Alt={
                        data.datoCmsReferenz.content[i].image[2] !== null
                          ? data.datoCmsReferenz.content[i].image[2]?.alt
                          : null
                      }
                      Img4={
                        data.datoCmsReferenz.content[i].image[3] !== null
                          ? imageSrc(data.datoCmsReferenz.content[i].image[3])
                          : null
                      }
                      Img4Alt={
                        data.datoCmsReferenz.content[i].image[3] !== null
                          ? data.datoCmsReferenz.content[i].image[3]?.alt
                          : null
                      }
                      Stacked={data.datoCmsReferenz.content[i].stacked}
                      Shadow={contentElement.shadow}
                    />
                  )
                case "DatoCmsSeparator":
                  return (
                    <Separator
                      key={i}
                      Bg={imageSrc(
                        data.datoCmsReferenz.content[i].hintergrundbild
                      )}
                      BgAlt={
                        data.datoCmsReferenz.content[i].hintergrundbild !== null
                          ? data.datoCmsReferenz.content[i].hintergrundbild?.alt
                          : null
                      }
                    />
                  )
                case "DatoCmsTextImage":
                  if (data.datoCmsReferenz.content[i].anker) {
                    sidebarItems.push(data.datoCmsReferenz.content[i].anker)
                  }
                  return (
                    <VisibilitySensor key={i} partialVisibility={true}>
                      <TextImage
                        key={i}
                        Anker={data.datoCmsReferenz.content[i].anker}
                        BgColor={
                          data.datoCmsReferenz.content[i].backgroundColor
                        }
                        H2={data.datoCmsReferenz.content[i].berschriftH2}
                        H3={data.datoCmsReferenz.content[i].berschriftH3}
                        List={data.datoCmsReferenz.content[i].bulletPoints}
                        Img1={imageSrc(data.datoCmsReferenz.content[i].image1)}
                        Img1Alt={
                          data.datoCmsReferenz.content[i].image1 !== null
                            ? data.datoCmsReferenz.content[i].image1?.alt
                            : {}
                        }
                        Img1Desc={
                          data.datoCmsReferenz.content[i].image1Description
                        }
                        Img2={imageSrc(data.datoCmsReferenz.content[i].image2)}
                        Img2Alt={
                          data.datoCmsReferenz.content[i].image2 !== null
                            ? data.datoCmsReferenz.content[i].image2?.alt
                            : {}
                        }
                        Img2Desc={
                          data.datoCmsReferenz.content[i].image2Description
                        }
                        ImgBelow={data.datoCmsReferenz.content[i].imageBelow}
                        Text={data.datoCmsReferenz.content[i].text}
                        TextIndent={data.datoCmsReferenz.content[i].textIndent}
                        ImgDesplacement={
                          data.datoCmsReferenz.content[i].imageDisplacement
                        }
                        ImageLeftOrRight={
                          data.datoCmsReferenz.content[i].imageLinksOderRecht
                        }
                        Shadow={contentElement.shadow}
                      />
                    </VisibilitySensor>
                  )
                case "DatoCmsImageSlider":
                  return <ImageSlider data={contentElement} />
                case "DatoCmsImageBanner":
                  return (
                    <div
                      key={contentElement.id}
                      className={styles.imageBanner}
                      style={{
                        backgroundImage: contentElement.backgroundImage
                          ? "url(" + contentElement.backgroundImage.url + ")"
                          : "linear-gradient( " +
                            contentElement.backgroundColorFadeOne.hex +
                            ", " +
                            contentElement.backgroundColorFadeTwo.hex +
                            ")",
                      }}
                    >
                      {contentElement.bannerImage.isImage ? (
                        <img src={imageSrc(contentElement.bannerImage)} />
                      ) : (
                        <video
                          controls={true}
                          poster={imageSrc(contentElement.videoCover)}
                          data-setup="{}"
                          loop={true}
                          className={styles.videoBanner}
                        >
                          <source
                            src={contentElement.bannerImage.video.mp4Url}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>
                  )
                case "DatoCmsMultiImageBanner":
                  return (
                    <div
                      className={styles.multiImageBanner}
                      key={contentElement.id}
                    >
                      {contentElement.bannerImages.map((image, i) => {
                        return (
                          <img
                            src={imageSrc(image)}
                            key={contentElement.id + i}
                          />
                        )
                      })}
                    </div>
                  )
                case "DatoCmsCtaBanner":
                  return (
                    <CtaBanner data={contentElement} key={contentElement.id} />
                  )
                case "DatoCmsQuote":
                  return (
                    <section
                      key={contentElement.id}
                      className={styles.quoteSection}
                    >
                      <div className={styles.quoteSectionDiv}>
                        <blockquote
                          dangerouslySetInnerHTML={{
                            __html: contentElement.text,
                          }}
                        />
                        <span></span>
                      </div>
                    </section>
                  )
                case "DatoCmsImageText":
                  return (
                    <ImageText data={contentElement} key={contentElement.id} />
                  )
                case "DatoCmsPictureDivider":
                  return (
                    <PicDivider
                      bgGradientColorLeft={contentElement.bgGradientColorLeft}
                      bgGradientColorRight={contentElement.bgGradientColorRight}
                      img={imageSrc(contentElement.imageFade)}
                      imgalt={contentElement.imageFade?.alt}
                      key={i}
                    />
                  )
                default:
                  return null
              }
            })}

            <OurProjects page="refrenz" />
          </div>
        </main>
      </div>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    datoCmsReferenz(id: { eq: $id }) {
      seo {
        description
        title
        twitterCard
      }
      id
      kategorie {
        name
      }
      kundeKurz
      kundeLang
      slug
      website
      ressort
      technologie
      titelDerReferenz
      teaserText
      teaserbild {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
      }
      image {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
        isImage
        video {
          thumbnailUrl
          mp4Url
        }
      }
      backgroundColorFadeOne {
        hex
      }
      backgroundColorFadeTwo {
        hex
      }
      backgroundImage {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
      }
      videoCover {
        fluid {
          src
        }
        url
      }
      content {
        ... on DatoCmsPictureDivider {
          id
          bgGradientColorLeft
          bgGradientColorRight
          imageFade: image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsMoodPicture {
          id
          backgroundColor
          shadow
          backgroundImage {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          heading
          imageDescription
          imageFullWidth {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsTestimonial {
          id
          anchorName
          image1 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          name1
          quote1
          internal {
            type
          }
        }
        ... on DatoCmsAufgabenstellung {
          id
          anker
          shadow
          berschriftH2
          text
          liste
          bild1 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          beschreibungBild1
          bild2 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
          beschreibungBild2
        }
        ... on DatoCmsTextWithOptionForAlignPosition {
          id
          anker
          internal {
            type
          }
          hintergrundfarbe
          optionSlimOn
          textLeftCenter
          textRightWithHtml
          berschriftH2
        }
        ... on DatoCmsMarke {
          id
          anker
          berschriftH2
          berschriftH3Farbe
          berschriftIcon
          berschriftPartnerH2
          description
          farbeImage {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          farbeImageDescription
          hintergrundbild {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          image1LogoDescription
          imageIconLogo {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          imageLogo {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          imageLogo2 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          imageLogo2Description
          internal {
            type
          }
          typographyBerschriftH3
          typographyLogo1 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          typographyLogo1Description
          typographyLogo2 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          typographyLogo2Description
          typographyLogo3 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          typographyLogo3Description
          ueberschriftLogoH3
        }
        ... on DatoCmsMultiImage {
          id
          hintergrundfarbe
          shadow
          internal {
            type
          }
          image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
          }
          stacked
        }
        ... on DatoCmsSeparator {
          id
          internal {
            type
          }
          hintergrundbild {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
        }
        ... on DatoCmsTextImage {
          id
          anker
          backgroundColor
          berschriftH2
          berschriftH3
          shadow
          image1 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          image1Description
          image2 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          image2Description
          imageBelow
          imageDisplacement
          imageLinksOderRecht
          internal {
            type
          }
          text
          textIndent
          bulletPoints
        }
        ... on DatoCmsImageSlider {
          id
          internal {
            type
          }
          images {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
            video {
              thumbnailUrl
              mp4Url
            }
          }
          imageSliderText: text {
            blocks
            value
            links
          }
        }
        ... on DatoCmsImageBanner {
          id
          internal {
            type
          }
          videoCover {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          bannerImage: image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
            video {
              thumbnailUrl
              mp4Url
            }
          }
          backgroundImage {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          backgroundColorFadeOne {
            hex
          }
          backgroundColorFadeTwo {
            hex
          }
        }
        ... on DatoCmsMultiImageBanner {
          id
          internal {
            type
          }
          bannerImages: images {
            alt
            fluid(imgixParams: { auto: "compress", w: "1000", h: "1000" }) {
              src
            }
          }
        }
        ... on DatoCmsCtaBanner {
          id
          internal {
            type
          }
          headline
          text
          linkButton {
            links {
              id: originalId
              slug
            }
            value
            blocks
          }
        }
        ... on DatoCmsQuote {
          id
          text
          internal {
            type
          }
        }
        ... on DatoCmsImageText {
          id
          internal {
            type
          }
          textBottomLeft: text {
            blocks
            value
            links
          }
          imageTopLeft {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
            video {
              thumbnailUrl
              mp4Url
            }
          }
          imageTopRight {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
            video {
              thumbnailUrl
              mp4Url
            }
          }
          imageBottomRight {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            isImage
            video {
              thumbnailUrl
              mp4Url
            }
          }
          previewTopLeft {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          previewTopRight {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          previewBottomRight {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          
        }
      }
    }
  }
`
