import React, { useState } from "react"
import styles from "./component.module.css"
import "swiper/swiper.min.css"
import imageSrc from "../../../lib/image-src"
import { StructuredText } from "react-datocms"

const ImageText = ({
  data: { imageTopLeft, imageTopRight, imageBottomRight, textBottomLeft, previewTopLeft, previewTopRight, previewBottomRight },
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {imageTopLeft.isImage ? (
          <img src={imageSrc(imageTopLeft)} className={styles.left} />
        ) : (
          <video
            controls={true}
            poster={imageSrc(previewTopLeft)}
            data-setup="{}"
            loop={true}
            className={styles.videoBanner}
          >
            <source src={imageTopLeft.video.mp4Url} type="video/mp4" />
          </video>
        )}
        {imageTopRight.isImage ? (
          <img src={imageSrc(imageTopRight)} className={styles.right} />
        ) : (
          <video
            controls={true}
            poster={imageSrc(previewTopRight)}
            data-setup="{}"
            loop={true}
            className={styles.videoBanner}
          >
            <source src={imageTopRight.video.mp4Url} type="video/mp4" />
          </video>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.text}>
        <StructuredText data={textBottomLeft} />
        </div>
        {imageBottomRight.isImage ? (
          <img src={imageSrc(imageBottomRight)} />
        ) : (
          <video
            controls={true}
            poster={imageSrc(previewBottomRight)}
            data-setup="{}"
            loop={true}
            className={styles.videoBanner}
          >
            <source src={imageBottomRight.video.mp4Url} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  )
}
export default ImageText
