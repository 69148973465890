import React from "react"
import styles from "./picture-divider.module.css"

const PicDivider = (props) => {
    return (
        <div className={styles.headerImg} style={{background: `linear-gradient(270deg, ${props.bgGradientColorLeft} 0%, ${props.bgGradientColorRight} 100%)`}}>
            {props.img &&
                <img src={props.img} alt={props.imgAlt} />
            }
        </div>
    )
}
export default PicDivider